var productName = 'scheduler';import Objects from './Objects.js';

/**
 * @module Core/helper/util/DomClassList
 */

/**
 * This class encapsulates a list of CSS classes which can be set as the `className`
 * on an `HTMLElement`.
 *
 * Properties names set on this class equate to *adding* a class if the property's value
 * is _truthy_, or removing a class if the value is _falsy_.
 *
 * ```javascript
 * const myClassList = new DomClassList('b-test-button');
 *
 * myClassList.add('test-class');
 * myClassList.important = 1;
 *
 * myHtmlElement.className = myClassList; // Sets it to "b-test-button test-class important"
 * ```
 */
export default class DomClassList {
    static change(cls, add, remove, as = 'string') {
        remove = DomClassList.normalize(remove, 'object');

        const after = DomClassList.normalize(cls, 'array').filter(c => !remove[c]);

        if (add) {
            add = DomClassList.normalize(add, 'array');

            for (let i = 0; i < add.length; ++i) {
                if (!after.includes(add[i])) {
                    after.push(add[i]);
                }
            }
        }

        return DomClassList.normalize(after, as);
    }

    /**
     *
     * @param {String|String[]|Object|HTMLElement} cls
     * @param {String} as Pass `'object'` to return an object with the class names as its
     * keys (all keys will have a value of `true`), or pass `'array'` to return an array
     * of class names, or pass `'string'` (the default) to return a space-separated string
     * of class names,
     * @returns {String|String[]|Object}
     * @private
     */
    static normalize(cls, as = 'string') {
        cls = cls || '';  // promote null to '' to avoid typeof snag

        const
            type = typeof cls,
            asArray = as === 'array',
            asObject = as === 'object',
            asString = !asArray && !asObject;

        let isString = type === 'string',
            c, ret;

        if (type === 'object') {
            if (cls.nodeType === 1 && typeof cls.getAttribute === 'function') {
                cls = cls.getAttribute('class');  // cannot use className for SVG el's
                isString = true;
            }
            else if (!Array.isArray(cls)) {
                cls = Objects.getTruthyKeys(cls);
            }
        }

        if (isString) {
            if (asString) {
                return cls;
            }

            cls = cls.split(' ');
        }

        // ret is now an array

        if (asArray) {
            ret = cls;
        }
        else if (asString) {
            ret = cls.join(' ');
        }
        else {
            ret = Object.create(null);

            for (c of cls) {
                ret[c] = true;
            }
        }

        return  ret;
    }

    /**
     * Initializes a new DomClassList.
     * @param {String} classes CSS classes to create with
     * @function constructor
     */
    constructor(...classes) {
        if (typeof classes[0] === 'object') {
            Objects.assign(this, classes[0]);
        }
        else {
            this.process(1, classes);
        }

        // String value needs recalculating
        this[dirtySymbol] = true;
    }

    /**
     * Returns a clone of this DomClassList with all the same keys set.
     * @returns {Core.helper.util.DomClassList} A clone of this DomClassList.
     */
    clone() {
        return new DomClassList(this);
    }

    /**
     * Returns a Boolean value, indicating whether this ClassList has the specified CSS class name.
     * @param {String} className CSS class name to check
     * @return {Boolean} true if this ClassList contains the passed CSS class name, false otherwise
     */
    contains(className) {
        if (typeof className === 'string' && className) {
            return Boolean(this[className]);
        }
        return false;
    }

    // An instance of this class may be assigned directly to an element's className
    // it will be coerced to a string value using this method.
    toString() {
        // Adding space at the end if there is content to make concatenation code simpler in renderers.
        return this.length ? `${this.value} ` : '';
    }

    toJSON() {
        return this.toString();
    }

    /**
     * Analogous to string.trim, returns the string value of this `DomClassList` with no trailing space.
     * @returns {String} A concatenated string value of all the class names in this `DomClassList`
     * separated by spaces.
     */
    trim() {
        return this.value;
    }

    /**
     * Compares this ClassList to another ClassList (or class name string of space separated classes).
     * If the *same class names, regardless of order* are present, the two are considered equal.
     *
     * So `new DomClassList('foo bar bletch').isEqual('bletch bar foo')` would return `true`
     * @param {Core.helper.util.DomClassList|String} other The `DomClassList` or string of classes to compare to.
     * @returns {Boolean} `true` if the two contain the same class names.
     */
    isEqual(other) {
        if (typeof other === 'string') {
            testClassList.value = other;
            other = testClassList;
        }

        if (this.length === other.length) {
            const otherClasses = Objects.getTruthyKeys(other);

            for (let i = 0, len = otherClasses.length; i < len; i++) {
                if (!this[otherClasses[i]]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    /**
     * Get/set string value.
     * Class names separated with space.
     * @property {String}
     */
    get value() {
        const me = this;

        if (me[dirtySymbol]) {
            const keys = Objects.getTruthyKeys(me);
            me[lengthSymbol] = keys.length;
            me[valueSymbol] = keys.join(' ');
            me[dirtySymbol] = false;
        }
        return me[valueSymbol];
    }

    set value(value) {
        const
            me = this,
            keys = Object.keys(me),
            len = keys.length;

        for (let i = 0; i < len; i++) {
            delete me[keys[i]];
        }

        if (value) {
            me.process(1, [value]);
        }
        else {
            // String value needs recalculating
            me[dirtySymbol] = true;
        }
    }

    /**
     * Returns string values as an array.
     * @readonly
     * @property {String[]}
     */
    get values() {
        return Objects.getTruthyKeys(this);
    }

    get length() {
        // Maintainer: We MUST access the value getter to force
        // the value to be calculated if it's currently dirty.
        return this.value ? this[lengthSymbol] : 0;
    }

    process(value, classes) {
        for (let i = 0, { length } = classes; i < length; i++) {
            const splitClasses = parseCls(classes[i]);

            for (let j = 0, { length } = splitClasses; j < length; j++) {
                if (splitClasses[j]) {
                    
                    this[splitClasses[j]] = value;
                }
            }
        }

        // String value needs recalculating
        this[dirtySymbol] = true;

        return this;
    }

    /**
     * Adds/removes class names according to the passed object's properties.
     *
     * Properties with truthy values are added.
     * Properties with false values are removed.
     * @param {Object} classList Object containing properties to set/clear
     */
    assign(classList) {
        for (const cls in classList) {
            if (Boolean(this[cls]) !== Boolean(classList[cls])) {
                this[cls] = classList[cls];

                // String value needs recalculating
                this[dirtySymbol] = true;
            }
        }
        return this;
    }

    /**
     * Add CSS class(es)
     * ```
     * myClassList.add('bold', 'small');
     * ```
     * @param {String} classes CSS classes to add
     */
    add(...classes) {
        return this.process(1, classes);
    }

    /**
     * Remove CSS class(es)
     * ```
     * myClassList.remove('bold', 'small');
     * ```
     * @param {String} classes CSS classes to remove
     */
    remove(...classes) {
        return this.process(0, classes);
    }

    /**
     * Analogous to the `String#split` method, but with no delimiter
     * parameter. This method returns an array containing the individual
     * CSS class names set.
     * @returns {String[]} The individual class names in this `DomClassList`
     */
    split() {
        return Objects.getTruthyKeys(this);
    }

    forEach(fn) {
        return Objects.getTruthyKeys(this).forEach(fn);
    }

    // To gain some speed in DomHelper.sync(), faster than instanceof etc
    get isDomClassList() {
        return true;
    }
};

const
    whiteSpaceRe  = /\s+/,
    valueSymbol   = Symbol('value'),
    lengthSymbol  = Symbol('length'),
    dirtySymbol   = Symbol('dirty'),
    testClassList = new DomClassList(),
    emptyArray    = Object.freeze([]),
    parseCls = cls => {
        if (cls?.isDomClassList) {
            return cls.values;
        }
        if (cls instanceof DOMTokenList) {
            return Array.from(cls);
        }
        if (typeof cls === 'object') {
            return Objects.getTruthyKeys(cls);
        }
        if (cls?.split) {
            return cls.split(whiteSpaceRe);
        }
        if (Array.isArray(cls)) {
            return cls;
        }
        return emptyArray;
    };
DomClassList._$name = 'DomClassList';