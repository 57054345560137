var productName = 'scheduler';/* eslint-disable import/first */

if (!window.bryntum) window.bryntum = {};

/*
 IMPORTANT!
 This entry SHOULD NOT INCLUDE ANY POLYFILLS. It should merely list all our classes that we want to include to the bundle.
 Entry is shared between salesforce bundle which cannot use polyfills, and regular bundles which do require those.
 */

//region Core (import)

// core
import Base from './lib/Core/Base.js';
import Config from './lib/Core/Config.js';
import GlobalEvents from './lib/Core/GlobalEvents.js';

// data
import AjaxStore from './lib/Core/data/AjaxStore.js';
import DomDataStore from './lib/Core/data/DomDataStore.js';
import Duration from './lib/Core/data/Duration.js';
import DataField from './lib/Core/data/field/DataField.js';
import BooleanDataField from './lib/Core/data/field/BooleanDataField.js';
import DateDataField from './lib/Core/data/field/DateDataField.js';
import IntegerDataField from './lib/Core/data/field/IntegerDataField.js';
import ModelDataField from './lib/Core/data/field/ModelDataField.js';
import NumberDataField from './lib/Core/data/field/NumberDataField.js';
import ObjectDataField from './lib/Core/data/field/ObjectDataField.js';
import StringDataField from './lib/Core/data/field/StringDataField.js';
import Model from './lib/Core/data/Model.js';
import StateTrackingManager from './lib/Core/data/stm/StateTrackingManager.js';
import Store from './lib/Core/data/Store.js';
import Transaction from './lib/Core/data/stm/Transaction.js';

// helpers
import AjaxHelper from './lib/Core/helper/AjaxHelper.js';
import ArrayHelper from './lib/Core/helper/ArrayHelper.js';
import AsyncHelper from './lib/Core/helper/AsyncHelper.js';
import BrowserHelper from './lib/Core/helper/BrowserHelper.js';
import CSSHelper from './lib/Core/helper/CSSHelper.js';
import DateHelper from './lib/Core/helper/DateHelper.js';
import DomClassList from './lib/Core/helper/util/DomClassList.js';
import DomHelper from './lib/Core/helper/DomHelper.js';
import DomSync from './lib/Core/helper/DomSync.js';
import DragHelper from './lib/Core/helper/DragHelper.js';
import EventHelper from './lib/Core/helper/EventHelper.js';
import FunctionHelper from './lib/Core/helper/FunctionHelper.js';
import IdHelper from './lib/Core/helper/IdHelper.js';
import ObjectHelper from './lib/Core/helper/ObjectHelper.js';
import RandomGenerator from './lib/Core/helper/util/RandomGenerator.js';
import ResizeHelper from './lib/Core/helper/ResizeHelper.js';
import ResizeMonitor from './lib/Core/helper/ResizeMonitor.js';
import StringHelper from './lib/Core/helper/StringHelper.js';
import TemplateHelper from './lib/Core/helper/TemplateHelper.js';
import VersionHelper from './lib/Core/helper/VersionHelper.js';
import WidgetHelper from './lib/Core/helper/WidgetHelper.js';

// helper util
import DataGenerator from './lib/Core/helper/util/DataGenerator.js';
import DemoBot from './lib/Core/helper/util/DemoBot.js';
import Formatter from './lib/Core/helper/util/Formatter.js';
import Fullscreen from './lib/Core/helper/util/Fullscreen.js';
import NumberFormat from './lib/Core/helper/util/NumberFormat.js';
import Objects from './lib/Core/helper/util/Objects.js';
import Point from './lib/Core/helper/util/Point.js';
import Promissory from './lib/Core/helper/util/Promissory.js';
import Rectangle from './lib/Core/helper/util/Rectangle.js';
import Scroller from './lib/Core/helper/util/Scroller.js';

// localization
import LocaleManager from './lib/Core/localization/LocaleManager.js';
import Localizable from './lib/Core/localization/Localizable.js';
import LocaleHelper from './lib/Core/localization/LocaleHelper.js';

// mixin
import Delayable from './lib/Core/mixin/Delayable.js';
import Draggable from './lib/Core/mixin/Draggable.js';
import Droppable from './lib/Core/mixin/Droppable.js';
import Events from './lib/Core/mixin/Events.js';
import Factoryable from './lib/Core/mixin/Factoryable.js';
import Featureable from './lib/Core/mixin/Featureable.js';
import Finalizable from './lib/Core/mixin/Finalizable.js';
import Hoverable from './lib/Core/mixin/Hoverable.js';
import InstancePlugin from './lib/Core/mixin/InstancePlugin.js';
import Override from './lib/Core/mixin/Override.js';
import Pluggable from './lib/Core/mixin/Pluggable.js';
import State from './lib/Core/mixin/State.js';

// util
import Bag from './lib/Core/util/Bag.js';
import ClickRepeater from './lib/Core/util/ClickRepeater.js';
import Collection from './lib/Core/util/Collection.js';
import CollectionFilter from './lib/Core/util/CollectionFilter.js';
import CollectionSorter from './lib/Core/util/CollectionSorter.js';
import DragContext from './lib/Core/util/drag/DragContext.js';
import DragProxy from './lib/Core/util/drag/DragProxy.js';
import DragTipProxy from './lib/Core/util/drag/DragTipProxy.js';
import DynamicObject from './lib/Core/util/DynamicObject.js';
import Month from './lib/Core/util/Month.js';
import Parser from './lib/Core/util/Parser.js';

// widget
import Badge from './lib/Core/widget/mixin/Badge.js';
import BooleanCombo from './lib/Core/widget/BooleanCombo.js';
import Button from './lib/Core/widget/Button.js';
import ButtonGroup from './lib/Core/widget/ButtonGroup.js';
import CalendarPanel from './lib/Core/widget/CalendarPanel.js';
import Checkbox from './lib/Core/widget/Checkbox.js';
import ChipView from './lib/Core/widget/ChipView.js';
import Combo from './lib/Core/widget/Combo.js';
import Container from './lib/Core/widget/Container.js';
import DateField from './lib/Core/widget/DateField.js';
import DatePicker from './lib/Core/widget/DatePicker.js';
import DateTimeField from './lib/Core/widget/DateTimeField.js';

import DisplayField from './lib/Core/widget/DisplayField.js';
import DurationField from './lib/Core/widget/DurationField.js';
import Editor from './lib/Core/widget/Editor.js';
import Field from './lib/Core/widget/Field.js';
import FileField from './lib/Core/widget/FileField.js';
import FilePicker from './lib/Core/widget/FilePicker.js';
import FilterField from './lib/Core/widget/FilterField.js';
import List from './lib/Core/widget/List.js';
import Mask from './lib/Core/widget/Mask.js';
import Menu from './lib/Core/widget/Menu.js';
import MenuItem from './lib/Core/widget/MenuItem.js';
import MessageDialog from './lib/Core/widget/MessageDialog.js';
import NumberField from './lib/Core/widget/NumberField.js';
import PagingToolbar from './lib/Core/widget/PagingToolbar.js';
import Panel from './lib/Core/widget/Panel.js';
import Popup from './lib/Core/widget/Popup.js';
import Renderable from './lib/Core/widget/Renderable.js';
import Ripple from './lib/Core/widget/Ripple.js';
import Slider from './lib/Core/widget/Slider.js';
import Splitter from './lib/Core/widget/Splitter.js';
import TabPanel from './lib/Core/widget/TabPanel.js';
import TextAreaField from './lib/Core/widget/TextAreaField.js';
import TextField from './lib/Core/widget/TextField.js';
import TimeField from './lib/Core/widget/TimeField.js';
import TimePicker from './lib/Core/widget/TimePicker.js';
import Toast from './lib/Core/widget/Toast.js';
import Tool from './lib/Core/widget/Tool.js';
import Toolbar from './lib/Core/widget/Toolbar.js';
import Tooltip from './lib/Core/widget/Tooltip.js';
import Widget from './lib/Core/widget/Widget.js';

//endregion

//region Grid (import)

// columns
import ActionColumn from './lib/Grid/column/ActionColumn.js';
import AggregateColumn from './lib/Grid/column/AggregateColumn.js';
import CheckColumn from './lib/Grid/column/CheckColumn.js';
import Column from './lib/Grid/column/Column.js';
import DateColumn from './lib/Grid/column/DateColumn.js';
import PercentColumn from './lib/Grid/column/PercentColumn.js';
import RatingColumn from './lib/Grid/column/RatingColumn.js';
import RowNumberColumn from './lib/Grid/column/RowNumberColumn.js';
import TemplateColumn from './lib/Grid/column/TemplateColumn.js';
import TimeColumn from './lib/Grid/column/TimeColumn.js';
import TreeColumn from './lib/Grid/column/TreeColumn.js';
import WidgetColumn from './lib/Grid/column/WidgetColumn.js';

// data
import ColumnStore from './lib/Grid/data/ColumnStore.js';
import GridRowModel from './lib/Grid/data/GridRowModel.js';

// features
import CellEdit from './lib/Grid/feature/CellEdit.js';
import CellMenu from './lib/Grid/feature/CellMenu.js';
import CellTooltip from './lib/Grid/feature/CellTooltip.js';
import ColumnAutoWidth from './lib/Grid/feature/ColumnAutoWidth.js';
import ColumnDragToolbar from './lib/Grid/feature/ColumnDragToolbar.js';
import ColumnPicker from './lib/Grid/feature/ColumnPicker.js';
import ColumnReorder from './lib/Grid/feature/ColumnReorder.js';
import ColumnResize from './lib/Grid/feature/ColumnResize.js';
import ContextMenu from './lib/Grid/feature/ContextMenu.js';
import Filter from './lib/Grid/feature/Filter.js';
import FilterBar from './lib/Grid/feature/FilterBar.js';
import GridFeatureManager from './lib/Grid/feature/GridFeatureManager.js';
import Group from './lib/Grid/feature/Group.js';
import HeaderMenu from './lib/Grid/feature/HeaderMenu.js';
import QuickFind from './lib/Grid/feature/QuickFind.js';
import RegionResize from './lib/Grid/feature/RegionResize.js';
import RowReorder from './lib/Grid/feature/RowReorder.js';
import Search from './lib/Grid/feature/Search.js';
import Sort from './lib/Grid/feature/Sort.js';
import Stripe from './lib/Grid/feature/Stripe.js';
import Tree from './lib/Grid/feature/Tree.js';

// export
import GridExportDialog from './lib/Grid/view/export/ExportDialog.js';
import GridMultiPageExporter from './lib/Grid/feature/export/exporter/MultiPageExporter.js';
import GridMultiPageVerticalExporter from './lib/Grid/feature/export/exporter/MultiPageVerticalExporter.js';
import GridPdfExport from './lib/Grid/feature/export/PdfExport.js';
import GridSinglePageExporter from './lib/Grid/feature/export/exporter/SinglePageExporter.js';
import ExportOrientationCombo from './lib/Grid/view/export/field/ExportOrientationCombo.js';
import ExportRowsCombo from './lib/Grid/view/export/field/ExportRowsCombo.js';
import LocalizableCombo from './lib/Grid/view/export/field/LocalizableCombo.js';
import { PaperFormat, Orientation, RowsRange, FileFormat } from './lib/Grid/feature/export/Utils.js';

// feature experimental
import GridExcelExporter from './lib/Grid/feature/experimental/ExcelExporter.js';

// util
import ScrollManager from './lib/Grid/util/ScrollManager.js';
import TableExporter from './lib/Grid/util/TableExporter.js';

// view
import Footer from './lib/Grid/view/Footer.js';
import Grid from './lib/Grid/view/Grid.js';
import GridBase from './lib/Grid/view/GridBase.js';
import Header from './lib/Grid/view/Header.js';
import RowManager from './lib/Grid/row/RowManager.js';
import TreeGrid from './lib/Grid/view/TreeGrid.js';

//endregion

//region Scheduler (import)

// crud
import AbstractCrudManager from './lib/Scheduler/crud/AbstractCrudManager.js';
import AjaxTransport from './lib/Scheduler/crud/transport/AjaxTransport.js';
import CrudManagerView from './lib/Scheduler/crud/mixin/CrudManagerView.js';
import JsonEncoder from './lib/Scheduler/crud/encoder/JsonEncoder.js';

// data
import AbstractRecurrenceIterator from './lib/Scheduler/data/util/recurrence/AbstractRecurrenceIterator.js';
import AssignmentStore from './lib/Scheduler/data/AssignmentStore.js';
import CrudManager from './lib/Scheduler/data/CrudManager.js';
import ProjectCrudManager from './lib/Scheduler/data/mixin/ProjectCrudManager.js';
import DailyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/DailyRecurrenceIterator.js';
import DependencyStore from './lib/Scheduler/data/DependencyStore.js';
import EventStore from './lib/Scheduler/data/EventStore.js';
import MonthlyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/MonthlyRecurrenceIterator.js';
import RecurrenceDayRuleEncoder from './lib/Scheduler/data/util/recurrence/RecurrenceDayRuleEncoder.js';
import RecurrenceLegend from './lib/Scheduler/data/util/recurrence/RecurrenceLegend.js';
import ProjectConsumer from './lib/Scheduler/data/mixin/ProjectConsumer.js';
import RecurringEventsMixin from './lib/Scheduler/data/mixin/RecurringEventsMixin.js';
import RecurringTimeSpansMixin from './lib/Scheduler/data/mixin/RecurringTimeSpansMixin.js';
import ResourceStore from './lib/Scheduler/data/ResourceStore.js';
import ResourceTimeRangeStore from './lib/Scheduler/data/ResourceTimeRangeStore.js';
import TimeAxis from './lib/Scheduler/data/TimeAxis.js';
import WeeklyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/WeeklyRecurrenceIterator.js';
import YearlyRecurrenceIterator from './lib/Scheduler/data/util/recurrence/YearlyRecurrenceIterator.js';

// features
import ColumnLines from './lib/Scheduler/feature/ColumnLines.js';
import Dependencies from './lib/Scheduler/feature/Dependencies.js';
import DependencyCreation from './lib/Scheduler/feature/mixin/DependencyCreation.js';
import DependencyEdit from './lib/Scheduler/feature/DependencyEdit.js';
import DragBase from './lib/Scheduler/feature/base/DragBase.js';
import EventContextMenu from './lib/Scheduler/feature/EventContextMenu.js';
import EventDrag from './lib/Scheduler/feature/EventDrag.js';
import EventDragCreate from './lib/Scheduler/feature/EventDragCreate.js';
import EventDragSelect from './lib/Scheduler/feature/EventDragSelect.js';
import EventEdit from './lib/Scheduler/feature/EventEdit.js';
import EventFilter from './lib/Scheduler/feature/EventFilter.js';
import EventMenu from './lib/Scheduler/feature/EventMenu.js';
import EventResize from './lib/Scheduler/feature/EventResize.js';
import EventTooltip from './lib/Scheduler/feature/EventTooltip.js';
import GroupSummary from './lib/Scheduler/feature/GroupSummary.js';
import HeaderContextMenu from './lib/Scheduler/feature/HeaderContextMenu.js';
import HeaderZoom from './lib/Scheduler/feature/HeaderZoom.js';
import Labels from './lib/Scheduler/feature/Labels.js';
import NonWorkingTime from './lib/Scheduler/feature/NonWorkingTime.js';
import Pan from './lib/Scheduler/feature/Pan.js';
import RecurringEventEdit from './lib/Scheduler/feature/mixin/RecurringEventEdit.js';
import ResourceTimeRanges from './lib/Scheduler/feature/ResourceTimeRanges.js';
import ScheduleContextMenu from './lib/Scheduler/feature/ScheduleContextMenu.js';
import ScheduleMenu from './lib/Scheduler/feature/ScheduleMenu.js';
import ScheduleTooltip from './lib/Scheduler/feature/ScheduleTooltip.js';
import SimpleEventEdit from './lib/Scheduler/feature/SimpleEventEdit.js';
import Summary from './lib/Scheduler/feature/Summary.js';
import TimeAxisHeaderMenu from './lib/Scheduler/feature/TimeAxisHeaderMenu.js';
import TimeRanges from './lib/Scheduler/feature/TimeRanges.js';
import TimeSpanMenuBase from './lib/Scheduler/feature/base/TimeSpanMenuBase.js';
import TimeSpanRecordContextMenuBase from './lib/Scheduler/feature/base/TimeSpanRecordContextMenuBase.js';

// export
import MultiPageExporter from './lib/Scheduler/feature/export/exporter/MultiPageExporter.js';
import MultiPageVerticalExporter from './lib/Scheduler/feature/export/exporter/MultiPageVerticalExporter.js';
import PdfExport from './lib/Scheduler/feature/export/PdfExport.js';
import SchedulerExportDialog from './lib/Scheduler/view/export/SchedulerExportDialog.js';
import SinglePageExporter from './lib/Scheduler/feature/export/exporter/SinglePageExporter.js';
import ScheduleRangeCombo from './lib/Scheduler/view/export/field/ScheduleRangeCombo.js';
import { ScheduleRange } from './lib/Scheduler/feature/export/Utils.js';

// features experimental
import ExcelExporter from './lib/Scheduler/feature/experimental/ExcelExporter.js';

// columns
import ResourceInfoColumn from './lib/Scheduler/column/ResourceInfoColumn.js';
import TimeAxisColumn from './lib/Scheduler/column/TimeAxisColumn.js';

// model
import AssignmentModel from './lib/Scheduler/model/AssignmentModel.js';
import DependencyBaseModel from './lib/Scheduler/model/DependencyBaseModel.js';
import DependencyModel from './lib/Scheduler/model/DependencyModel.js';
import EventModel from './lib/Scheduler/model/EventModel.js';
import ProjectModel from './lib/Scheduler/model/ProjectModel.js';
import RecurrenceModel from './lib/Scheduler/model/RecurrenceModel.js';
import RecurringTimeSpan from './lib/Scheduler/model/mixin/RecurringTimeSpan.js';
import ResourceModel from './lib/Scheduler/model/ResourceModel.js';
import ResourceTimeRangeModel from './lib/Scheduler/model/ResourceTimeRangeModel.js';
import TimeSpan from './lib/Scheduler/model/TimeSpan.js';

// preset
import PresetManager from './lib/Scheduler/preset/PresetManager.js';
import PresetStore from './lib/Scheduler/preset/PresetStore.js';
import ViewPreset from './lib/Scheduler/preset/ViewPreset.js';

// util
import RectangularPathFinder from './lib/Scheduler/util/RectangularPathFinder.js';
import ScheduleTableExporter from './lib/Scheduler/util/ScheduleTableExporter.js';

//view
import HorizontalTimeAxis from './lib/Scheduler/view/HorizontalTimeAxis.js';
import RecurrenceCombo from './lib/Scheduler/view/recurrence/field/RecurrenceCombo.js';
import RecurrenceConfirmationPopup from './lib/Scheduler/view/recurrence/RecurrenceConfirmationPopup.js';
import RecurrenceDaysButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceDaysButtonGroup.js';
import RecurrenceDaysCombo from './lib/Scheduler/view/recurrence/field/RecurrenceDaysCombo.js';
import RecurrenceEditor from './lib/Scheduler/view/recurrence/RecurrenceEditor.js';
import RecurrenceFrequencyCombo from './lib/Scheduler/view/recurrence/field/RecurrenceFrequencyCombo.js';
import RecurrenceLegendButton from './lib/Scheduler/view/recurrence/RecurrenceLegendButton.js';
import RecurrenceMonthDaysButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceMonthDaysButtonGroup.js';
import RecurrenceMonthsButtonGroup from './lib/Scheduler/view/recurrence/field/RecurrenceMonthsButtonGroup.js';
import RecurrencePositionsCombo from './lib/Scheduler/view/recurrence/field/RecurrencePositionsCombo.js';
import RecurrenceStopConditionCombo from './lib/Scheduler/view/recurrence/field/RecurrenceStopConditionCombo.js';
import Scheduler from './lib/Scheduler/view/Scheduler.js';
import SchedulerBase from './lib/Scheduler/view/SchedulerBase.js';
import TimeAxisViewModel from './lib/Scheduler/view/model/TimeAxisViewModel.js';

// widget
import ResourceFilter from './lib/Scheduler/widget/ResourceFilter.js';
import ResourceCombo from './lib/Scheduler/widget/ResourceCombo.js';
import UndoRedo from './lib/Scheduler/widget/UndoRedo.js';

//endregion

//region Tag (import)

// IMPORTANT NOTE: Tag should be imported last to be last in the rolled up bundle. We rely on its position to embed
// See SchedulerPro/Gruntfile.js for Tag substitute
import SchedulerTag from './lib/Scheduler/customElements/SchedulerTag.js';

//endregion

export {
    //region Core (export)

    // core
    Base,
    Config,
    GlobalEvents,

    // data
    AjaxStore,
    DomDataStore,
    Duration,
    DataField,
    BooleanDataField,
    DateDataField,
    IntegerDataField,
    ModelDataField,
    NumberDataField,
    ObjectDataField,
    StringDataField,
    Model,
    StateTrackingManager,
    Store,
    Transaction,

    // helpers
    AjaxHelper,
    ArrayHelper,
    AsyncHelper,
    BrowserHelper,
    CSSHelper,
    DateHelper,
    DomClassList,
    DomHelper,
    DomSync,
    DragHelper,
    EventHelper,
    FunctionHelper,
    IdHelper,
    ObjectHelper,
    RandomGenerator,
    ResizeHelper,
    ResizeMonitor,
    StringHelper,
    TemplateHelper,
    VersionHelper,
    WidgetHelper,

    // helper util
    DataGenerator,
    DemoBot,
    Formatter,
    Fullscreen,
    NumberFormat,
    Objects,
    Point,
    Promissory,
    Rectangle,
    Scroller,

    // localization
    LocaleManager,
    Localizable,
    LocaleHelper,

    // mixin
    Delayable,
    Draggable,
    Droppable,
    Events,
    Factoryable,
    Featureable,
    Finalizable,
    Hoverable,
    InstancePlugin,
    Override,
    Pluggable,
    State,

    // util
    Bag,
    ClickRepeater,
    Collection,
    CollectionFilter,
    CollectionSorter,
    DragContext,
    DragProxy,
    DragTipProxy,
    DynamicObject,
    Month,
    Parser,

    // widget
    Badge,
    BooleanCombo,
    Button,
    ButtonGroup,
    CalendarPanel,
    Checkbox,
    ChipView,
    Combo,
    Container,
    DateField,
    DatePicker,
    DateTimeField,
    DisplayField,
    DurationField,
    Editor,
    Field,
    FileField,
    FilePicker,
    FilterField,
    List,
    Mask,
    Menu,
    MenuItem,
    MessageDialog,
    NumberField,
    PagingToolbar,
    Panel,
    Popup,
    Renderable,
    Ripple,
    Slider,
    Splitter,
    TabPanel,
    TextAreaField,
    TextField,
    TimeField,
    TimePicker,
    Toast,
    Tool,
    Toolbar,
    Tooltip,
    Widget,

    //endregion

    //region Grid (export)

    // columns
    ActionColumn,
    AggregateColumn,
    CheckColumn,
    Column,
    DateColumn,
    PercentColumn,
    RatingColumn,
    RowNumberColumn,
    TemplateColumn,
    TimeColumn,
    TreeColumn,
    WidgetColumn,

    // data
    ColumnStore,
    GridRowModel,

    // features
    CellEdit,
    CellMenu,
    CellTooltip,
    ColumnAutoWidth,
    ColumnDragToolbar,
    ColumnPicker,
    ColumnReorder,
    ColumnResize,
    ContextMenu,
    Filter,
    FilterBar,
    GridFeatureManager,
    Group,
    HeaderMenu,
    QuickFind,
    RegionResize,
    RowReorder,
    Search,
    Sort,
    Stripe,
    Tree,

    // export
    FileFormat,
    GridExportDialog,
    GridMultiPageExporter,
    GridMultiPageVerticalExporter,
    GridPdfExport,
    GridSinglePageExporter,
    ExportOrientationCombo,
    ExportRowsCombo,
    LocalizableCombo,
    Orientation,
    PaperFormat,
    RowsRange,

    // feature experimental
    GridExcelExporter,

    // util
    ScrollManager,
    TableExporter,

    // view
    Footer,
    Grid,
    GridBase,
    Header,
    RowManager,
    TreeGrid,

    //endregion

    //region Scheduler (export)

    // crud
    AbstractCrudManager,
    AjaxTransport,
    CrudManagerView,
    JsonEncoder,

    // data
    AbstractRecurrenceIterator,
    AssignmentStore,
    CrudManager,
    ProjectCrudManager,
    DailyRecurrenceIterator,
    DependencyStore,
    EventStore,
    MonthlyRecurrenceIterator,
    RecurrenceDayRuleEncoder,
    RecurrenceLegend,
    ProjectConsumer,
    RecurringEventsMixin,
    RecurringTimeSpansMixin,
    ResourceStore,
    ResourceTimeRangeStore,
    TimeAxis,
    WeeklyRecurrenceIterator,
    YearlyRecurrenceIterator,

    // features
    ColumnLines,
    Dependencies,
    DependencyEdit,
    DragBase,
    DependencyCreation,
    EventContextMenu,
    EventDrag,
    EventDragCreate,
    EventDragSelect,
    EventEdit,
    EventFilter,
    EventMenu,
    EventResize,
    EventTooltip,
    GroupSummary,
    HeaderContextMenu,
    HeaderZoom,
    Labels,
    NonWorkingTime,
    Pan,
    RecurringEventEdit,
    ResourceTimeRanges,
    ScheduleContextMenu,
    ScheduleMenu,
    ScheduleTooltip,
    SimpleEventEdit,
    Summary,
    TimeAxisHeaderMenu,
    TimeRanges,
    TimeSpanMenuBase,
    TimeSpanRecordContextMenuBase,

    // export
    MultiPageExporter,
    MultiPageVerticalExporter,
    PdfExport,
    SchedulerExportDialog,
    SinglePageExporter,
    ScheduleRangeCombo,
    ScheduleRange,

    // features experimental
    ExcelExporter,

    // columns
    ResourceInfoColumn,
    TimeAxisColumn,

    // model
    AssignmentModel,
    DependencyBaseModel,
    DependencyModel,
    EventModel,
    ProjectModel,
    RecurrenceModel,
    RecurringTimeSpan,
    ResourceModel,
    ResourceTimeRangeModel,
    TimeSpan,

    // preset
    PresetManager,
    PresetStore,
    ViewPreset,

    // util
    RectangularPathFinder,
    ScheduleTableExporter,

    //view
    HorizontalTimeAxis,
    RecurrenceCombo,
    RecurrenceConfirmationPopup,
    RecurrenceDaysButtonGroup,
    RecurrenceDaysCombo,
    RecurrenceEditor,
    RecurrenceFrequencyCombo,
    RecurrenceLegendButton,
    RecurrenceMonthDaysButtonGroup,
    RecurrenceMonthsButtonGroup,
    RecurrencePositionsCombo,
    RecurrenceStopConditionCombo,
    Scheduler,
    SchedulerBase,
    TimeAxisViewModel,

    // widget
    ResourceFilter,
    ResourceCombo,
    UndoRedo,

    //endregion

    //region Tag (export)

    // IMPORTANT NOTE: Tag should be imported last to be last in the rolled up bundle. We rely on its position to embed
    // See SchedulerPro/Gruntfile.js for Tag substitute
    SchedulerTag

    //endregion

};
